//----------  fonts
//------------------------------------------------------------------------------

@font-face {
  font-family: 'FreightText Pro';
  src: local('FreightText Pro'), local('FreigTexProMedIt'),
  url('../fonts/FreigTexProMedIt.woff2') format('woff2'),
  url('../fonts/FreigTexProMedIt.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: local('MaisonNeue'), local('MaisonNeue-Black'),
  url('../fonts/MaisonNeue-Black.woff2') format('woff2'),
  url('../fonts/MaisonNeue-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: local('MaisonNeue'), local('MaisonNeue-Bold'),
  url('../fonts/MaisonNeue-Bold.woff2') format('woff2'),
  url('../fonts/MaisonNeue-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: local('MaisonNeue'), local('MaisonNeue-Demi'),
  url('../fonts/MaisonNeue-Demi.woff2') format('woff2'),
  url('../fonts/MaisonNeue-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: local('MaisonNeue'), local('MaisonNeue-Medium'),
  url('../fonts/MaisonNeue-Medium.woff2') format('woff2'),
  url('../fonts/MaisonNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: local('MaisonNeue'), local('MaisonNeue-Book'),
  url('../fonts/MaisonNeue-Book.woff2') format('woff2'),
  url('../fonts/MaisonNeue-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: local('MaisonNeue'), local('MaisonNeue-Light'),
  url('../fonts/MaisonNeue-Light.woff2') format('woff2'),
  url('../fonts/MaisonNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}